import React, {useState} from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Page from "../../components/atoms/page";
import wood from "../../images/deals/wood.jpg";
import pizza from "../../images/deals/25-off/25-off.png";
import bogo from "../../images/deals/25-off/25-off.svg";
import './deals.css';
import Locator from "../../components/locator/Locator";
//import {stores} from "../../data/stores";
import {getParticipatingStores} from "../../helpers/location"
import MyModal from "../../components/atoms/modal/myModal";
import {graphql} from "gatsby";
import {deals_footer_text} from "../../helpers/constants";
import Img from "gatsby-image"

function TwentyFiveEntireOrder({data}) {
    const title = 'Stronger Together - Sacramento';
    let stores = data.allContentfulStores;

    const [isModalOpen, setIsModalOpen] = useState(false);

    function openModel() {
        setIsModalOpen(true);
    }

    function toggleModal() {
        setIsModalOpen(!isModalOpen);
    }

    let footerMessage = (
        <>
            <p>{deals_footer_text}</p>
        </>
    );

    let participatingStores = [
        101, 102, 103, 104, 105, 107,
        108, 109, 110, 111, 112, 113,
        114, 115, 117, 119, 122, 124,
        125, 127, 129, 133, 137, 138,
        143, 144, 146, 147, 149, 152,
        154, 156, 159, 160, 161, 163,
        165, 174, 184, 185, 186, 188
    ];


    let myStores = getParticipatingStores(stores, participatingStores);

    return (
        <Layout useMain={true} hideMenu={true} customFooterMessage={footerMessage}>
            <SEO
                title={title}
                keywords={[`Stronger Together`, `American Cancer Society`]}
            />
            <Page hideTitle={true} title={title} bgImage={wood} transparentPage={true}>


                <div className="flex flex-wrap">
                    <div className="w-full sm:w-full md:w-2/5 mb-4 md:pr-10 pb-10">

                        <div className="bg-black rounded-top" style={{backgroundColor: '#E27AAB'}}>
                            <div className={'p-5 text-white font-bold'}>
                                <div>
                                    <span className={'inline-block text-2xl align-top'}>$</span> <span
                                    className={' inline-block text-6xl align-top'}
                                    style={{lineHeight: 1}}>18</span><span
                                    className={'inline-block text-2xl align-top'}>99</span><br/>
                                </div>
                                <span className={'text-2xl'}>Any Large Specialty Pizza</span>
                            </div>
                            <Img
                                fluid={data.textImage.fluid}
                                className="block mx-auto w-full"
                                alt="Combination and Pepperoni Pizza"
                            />

                            <div className="bg-white order-now-div">
                            <span className="h2">
                                <a className={"text-pink-400 font-bold sm:text-md md:text-3xl lg:text-4xl"} href={'#/'}
                                   onClick={openModel}>ORDER NOW ></a>
                            </span>
                            </div>

                            <div className="bg-black rounded-bottom">
                            </div>

                        </div>
                    </div>
                    <div className="w-full sm:w-full md:w-3/5 mb-4 md:pr-10 md:pb-10">
                        <Img
                            fluid={data.pizzaImage.fluid}
                            className="block mx-auto w-full"
                            alt="Combination and Pepperoni Pizza"
                        />
                    </div>
                </div>

                <MyModal title={'Start Order'} isOpen={isModalOpen} toggleModal={toggleModal}>
                    <div className={'bg-gray-100'}>
                        <Locator
                            storesPerPage={4}
                            showDetails={false}
                            showPhone={false}
                            stores={myStores}
                            allCompanyStores={stores.edges}
                        />
                    </div>
                </MyModal>


            </Page>
        </Layout>
    );
}

export default TwentyFiveEntireOrder;

export const query = graphql`query {
    allContentfulStores (filter: {node_locale: {eq: "en-US"}}) {
    edges {
      node {
        id
        contentful_id
        phoneNumber
        storeId
        visibleStoresMap
        specialUrl
        rating
        orderUrl
        menuUrl
        coordinates {
          lat
          lon
        }
        address {
          city
          country
          state
          streetName
          streetName2
          streetNumber
          streetSuffix
          zipCode
        }
      }
    }
  }
   textImage:imageSharp(fixed: {originalName: {eq: "stronger-together-text-sac.png"}}) {
        fluid {
          ...GatsbyImageSharpFluid
        }
      
      }
      
        pizzaImage:imageSharp(fixed: {originalName: {eq: "slice-of-hope.jpg"}}) {
        fluid {
          ...GatsbyImageSharpFluid
        }
      
      }
}
  `;
